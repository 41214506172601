export const DATA_TYPES = {
  NUMBER: "number",
  STRING: "string",
  ARRAY: "array",
  OBJECT: "object",
  NULL: "null",
  UNDEFINED: "undefined",
  BOOLEAN: "boolean",
  FUNCTION: "function",
};

export const MODAL_TYPES = {
  CREATE: "CREATE",
  EDIT: "EDIT",
};


export const LMS_ROLES = {
  ADMIN: 17,
  LEARNER: 18,
}


export const fileExtensions = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "svg",
  "webp",
  "tiff",
  "ico",
  "heif",
  "doc",
  "docx",
  "odt",
  "txt",
  "rtf",
  "csv",
  "md",
  "html",
  "xml",
  "pdf",
  "xls",
  "xlsx",
  "ods",
  "csv"
];