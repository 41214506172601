import { getLanguage } from "../AppRedux/Actions";

export const reset_url = () => {
  setTimeout(() => {
    window.top.location.href = window.top.location.origin;
  }, 1000);
};

export const clearStorage = (reload = true) => {
  reload && reset_url();
  return localStorage.clear();
};

export const setToken = (token) => {
  return localStorage.setItem(`token`, token);
};

export const getToken = () => {
  return localStorage.getItem(`token`) || false;
};

export const setUser = (user) => {
  return localStorage.setItem(`user`, JSON.stringify(user));
};

export const getUser = (name) => {
  const auth = JSON.parse(localStorage.getItem(`user`) || "{}");
  if (name && Object.hasOwnProperty.call(auth, name)) {
    return auth[name];
  }
  return auth;
};

export const getHeaders = (customHeader = {}) => {
  const headers = {
    app_name: `${process.env.REACT_APP_NAME}`,
    app_lang: getLanguage() || "en",
    "Content-Type": "application/json",
  };
  const token = getToken();
  if (token) {
    headers.token = token;
  }
  for (const name in customHeader) {
    if (customHeader[name]) {
      headers[name] = customHeader[name];
    }
  }
  return headers;
};
