import { toast } from "react-toastify";

const COMMON_CONFIG = {
  delay: 500,
};
export const showToast = (message, type) => {
  if (message) {
    switch (type) {
      case "success":
        toast.success(message, {
          ...COMMON_CONFIG,
          style: {
            backgroundColor: "#EAF7EE",
            border: "1px solid #a9d9b8b0",
            color: "#000000",
          },
        });
        break;
      case "error":
        toast.error(message, {
          ...COMMON_CONFIG,
          style: {
            backgroundColor: "#FCEDE8",
            border: "1px solid #d9bab09c",
            color: "#000000",
          },
        });
        break;
      default:
        toast(message, COMMON_CONFIG);
    }
  }
};
