import { createTheme, alpha, ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const colorConvert = {
  "#335aff": "#FFFFFF",
  "#3B5352": "#FFFFFF",
  "#34004B": "#FFFFFF",
  "#FFFFFF": "#000000",
};

function ThemeSetup({ children }) {
  const { user } = useSelector((state) => state.auth);
  const [primaryColor, setPrimaryColor] = useState(
    user?.PRODUCT_THEME_COLOR || "#335aff",
  );
  const [secondaryColor, setSecondaryColor] = useState(
    colorConvert[user?.PRODUCT_THEME_COLOR || "#335aff"] || "#ffffff",
  );

  useEffect(() => {
    let pcolor = (user.PRODUCT_THEME_COLOR || "#335aff").toUpperCase();
    if (pcolor === "#FFFFFF") {
      pcolor = "#335aff";
    }
    setPrimaryColor(pcolor);
    setSecondaryColor(colorConvert[pcolor] || "#ffffff");
  }, [user]);
  const themeOptions = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        xxl: 1536,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            borderRadius: "var(--border-radius-small)",
            padding: "6px 18px",
          },
        },
        defaultProps: {
          variant: "contained",
          color: "primary",
        },
      },
      MuiRating: {
        styleOverrides: {
          root: {
            color: "var(--rating-color)",
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: "25px",
            fontSize: "var(--font-size-lg)",
            borderRadius: "var(--border-radius-medium)",
          },
        },
      },
      // MuiDialogTitle:{
      //     styleOverrides:{
      //         root:{

      //         }
      //     }
      // }
    },
    typography: {
      fontFamily: "var(--font-family-primary) !important",
      h1: {
        fontSize: "var(--font-size-xxl)",
        fontWeight: "var(--font-weight-bold)",
        lineHeight: "var(--line-height-tight)",
        letterSpacing: "var(--letter-spacing-tight)",
      },
      h2: {
        fontSize: "var(--font-size-xl)",
        fontWeight: "var(--font-weight-semibold)",
        lineHeight: "var(--line-height-sm)",
        letterSpacing: "var(--letter-spacing-tight)",
      },
      h3: {
        fontSize: "var(--font-size-base)",
        fontWeight: "var(--font-weight-medium)",
        lineHeight: "var(--line-height-sm)",
      },
      h4: {
        fontSize: "var(--font-size-base)",
        fontWeight: "var(--font-weight-medium)",
        lineHeight: "var(--line-height-base)",
        color: "var(--text-light)",
      },
      h5: {
        fontSize: "var(--font-size-sm)",
        fontWeight: "var(--font-weight-medium)",
        lineHeight: "var(--line-height-sm)",
        color: "var(--text-light)",
      },
      h6: {
        fontSize: "var(--font-size-xs)",
        fontWeight: "var(--font-weight-regular)",
        lineHeight: "var(--line-height-xs)",
      },
      subtitle1: {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: 1.5,
      },
      subtitle2: {
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: 1.5,
      },
      body1: {
        fontSize: "var(--font-size-xs)",
        fontWeight: 400,
        lineHeight: 1.5,
      },
      body2: {
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: 1.5,
      },
      caption: {
        fontSize: "0.75rem",
        fontWeight: 400,
        lineHeight: 1.4,
      },
      overline: {
        fontSize: "0.75rem",
        fontWeight: 400,
        lineHeight: 1.4,
        textTransform: "uppercase",
      },
      button: {
        fontSize: "0.875rem",
        fontWeight: 500,
        textTransform: "none",
      },

      button: {
        fontSize: "var(--font-size-xs)",
        fontWeight: "var(--font-weight-light)",
      },
    },
    zIndex: {
      drawer: 1300,
    },
    palette: {
      background: {
        primary: alpha(primaryColor, 1),
        secondary: alpha(primaryColor, 0.5),
      },
      primary: {
        main: primaryColor,
        other: secondaryColor,
        menu: alpha(secondaryColor, 0.7),
      },
      listhead: {
        main: "#ECECEC",
      },
    },
    spacing: 8,
    // shadows: ['none', `0 0 2px 0 ${alpha('#919EAB', 0.24)}, -20px 20px 40px -4px ${alpha('#919EAB', 0.24)}`].concat(Array(23).fill('none')),
    overrides: {
      MuiAppBar: {
        colorInherit: {
          backgroundColor: primaryColor,
          color: secondaryColor,
        },
      },
    },
    // props: {
    //     MuiTooltip: {
    //         arrow: true,
    //     },
    //     MuiButton: {
    //         size: 'small',
    //     },
    //     MuiButtonGroup: {
    //         size: 'small',
    //     },
    //     MuiCheckbox: {
    //         size: 'small',
    //     },
    //     MuiFab: {
    //         size: 'small',
    //     },
    //     MuiFormControl: {
    //         margin: 'dense',
    //         size: 'small',
    //     },
    //     MuiFormHelperText: {
    //         margin: 'dense',
    //     },
    //     MuiIconButton: {
    //         size: 'small',
    //     },
    //     MuiInputBase: {
    //         margin: 'dense',
    //     },
    //     MuiInputLabel: {
    //         margin: 'dense',
    //     },
    //     MuiRadio: {
    //         size: 'small',
    //     },
    //     MuiSwitch: {
    //         size: 'small',
    //     },
    //     MuiTextField: {
    //         margin: 'dense',
    //         size: 'small',
    //     },
    //     MuiList: {
    //         dense: true,
    //     },
    //     MuiMenuItem: {
    //         dense: true,
    //     },
    //     MuiTable: {
    //         size: 'small',
    //     },
    //     MuiButtonBase: {
    //         disableRipple: true,
    //     },
    //     MuiAppBar: {
    //         color: 'transparent',
    //     }
    // },
  });
  return <ThemeProvider theme={themeOptions}>{children}</ThemeProvider>;
}

export default ThemeSetup;
