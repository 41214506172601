import React from "react";
import "./index.css";
import { Provider } from "react-redux";
// import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { store } from "./AppRedux/store";
import Theme from "./Theme";
import ThemeSetup from "./Theme/themeOptions";
import FullLoader from "./Theme/FullLoader";

function App() {
  return (
    <Provider store={store}>
      <ThemeSetup>
        <FullLoader />
        {/* <CssBaseline /> */}
        <Router basename="/">
          <Switch>
            <Route path="/" component={Theme} />
          </Switch>
        </Router>
      </ThemeSetup>
    </Provider>
  );
}

export default App;
