import React from "react";
import { useSelector } from "react-redux";
import Loader from "./loader";

function FullLoader() {
  const { loading } = useSelector((state) => state.common);
  if (!loading) return null;
  return <Loader />;
}

export default FullLoader;
