import {
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_LOADER,
  HIDE_LOADER,
  APP_MENUS,
  PERCENTAGE,
} from "../ActionTypes";
import { getToken } from "../../Util/AuthStates";

const INIT_STATE = {
  loading: false,
  percentage: null,
  authUser: getToken(),
  menus: [],
};

const defaultReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        percentage: null,
        loading: false,
      };
    }
    case SHOW_LOADER: {
      return {
        ...state,
        loading: true,
      };
    }
    case HIDE_LOADER: {
      return {
        ...state,
        percentage: null,
        loading: false,
      };
    }
    case PERCENTAGE: {
      return {
        ...state,
        percentage: action.payload.percentage,
      };
    }
    case APP_MENUS: {
      return {
        ...state,
        menus: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default defaultReducers;
