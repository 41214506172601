import axios from "axios";
import { showToast } from "../constant";

import {
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_LOADER,
  HIDE_LOADER,
} from "../ActionTypes";

import display from "../../lang";
import { clearStorage, getHeaders, reset_url } from "../../Util/AuthStates";

export const get = (
  dispatch,
  url,
  headers,
  success,
  error = () => {},
  manual_loader = false,
) => {
  if (!manual_loader) dispatch({ type: FETCH_START });
  axios
    .get(`${window.env.REACT_APP_API_BASE_URL}/${url}`, {
      headers: getHeaders(headers || {}),
    })
    .then(({ data }) => {
      console.log("digvijay");
      if (!data.error) {
        if (data.message && data.message.length > 0) {
          showToast(data.message, "success");
        }
        if (!manual_loader) dispatch({ type: FETCH_SUCCESS });
        success(data.data);
      } else {
        dispatchError(data, dispatch, error, manual_loader);
      }
    })
    .catch((e) => {
      checkError(e, dispatch, error, manual_loader);
    });
};

export const post = (
  dispatch,
  url,
  payload,
  headers,
  success,
  error = () => {},
  manual_loader = false,
) => {
  if (!manual_loader) dispatch({ type: FETCH_START });
  axios
    .post(`${window.env.REACT_APP_API_BASE_URL}/${url}`, payload, {
      headers: getHeaders(headers || {}),
    })
    .then(({ data }) => {
      if (!data.error) {
        if (data.message && data.message.length > 0) {
          showToast(data.message, "success");
        }
        if (!manual_loader) dispatch({ type: FETCH_SUCCESS });
        success(data.data);
      } else {
        dispatchError(data, dispatch, error, manual_loader);
      }
    })
    .catch((e) => {
      checkError(e, dispatch, error, manual_loader);
    });
};

export const checkError = (e, dispatch, next, manual_loader = false) => {
  if (e.response?.status === 404 || e.response?.status === "404") {
    showError(dispatch, display("API_NOT_FOUND"), manual_loader);
    next();
  } else if (e.response?.data) {
    dispatchError(e.response.data, dispatch, next, manual_loader);
  } else if (e.message === "Network Error") {
    showError(dispatch, display("NETWORK_ERROR"), manual_loader);
    next();
  } else {
    showError(dispatch, e.message, manual_loader);
    next();
  }
};

export const dispatchError = (data, dispatch, next, manual_loader = false) => {
  if (data?.forceLogin) {
    clearStorage();
  }
  downloadError(data.message || "");
  showError(dispatch, data.message || null, manual_loader);
  next();
};

export const displayLoader = (dispatch) => {
  dispatch({ type: SHOW_LOADER });
};

export const hideLoader = (dispatch) => {
  dispatch({ type: HIDE_LOADER });
};

export const setLanguage = (lang) => {
  const d = new Date();
  d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `app_language=${lang};${expires};path=/`;
  window.top.location.reload();
};

export const getLanguage = () => {
  const name = "app_language=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const showError = (dispatch, message, manual_loader = false) => {
  if (message) showToast(message, "error");
  if (!manual_loader) hideLoader(dispatch);
};

export const downloadError = (errMessage = "") => {
  if (errMessage.indexOf("Row") !== -1) {
    exportErrData(errMessage);
  }
};

export const exportErrData = (errStr = "") => {
  const linkElement = document.createElement("a");
  try {
    const uri = "data:application/csv;base64,";
    const base64 = function (s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    };
    const csv_data = errStr.replace(/<br>/g, "\r\n");
    linkElement.setAttribute("href", uri + base64(csv_data));
    linkElement.setAttribute("download", "upload_err_log.csv");
    const clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    linkElement.dispatchEvent(clickEvent);
  } catch (ex) {}
};
